import { DateRange } from "react-date-range"
import "./header.css"
import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBed,
  faCalendarDays,
  faCar,
  faPerson,
  faPlane,
  faTaxi,
  faWandSparkles,
} from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import { format } from "date-fns"
import { useLocation, useNavigate } from "react-router-dom"

function Header({ type }) {
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ])
  const [options, setOptions] = useState({
    adult: 1,
    children: 0,
    room: 1,
  })
  const [destination, setDestination] = useState("")
  const [showDateRange, setShowDateRange] = useState(false)
  const [showOptions, setShowOptions] = useState(false)

  const handleOption = (name, action) => {
    setOptions((prev) => {
      return {
        ...prev,
        [name]: action === "i" ? options[name] + 1 : options[name] - 1,
      }
    })
  }

  const navigate = useNavigate()

  const handleSearch = () => {
    navigate("/hotels", { state: { destination, date, options } })
  }

  return (
    <div className="header">
      <div
        className={
          type === "list" ? "headerContainer listMode" : "headerContainer"
        }
      >
        <div className="headerList">
          <div className="headerListItem active">
            <FontAwesomeIcon icon={faBed} />
            <span>Stays</span>
          </div>
          <div className="headerListItem">
            <FontAwesomeIcon icon={faPlane} />
            <span>Flights</span>
          </div>
          <div className="headerListItem">
            <FontAwesomeIcon icon={faCar} />
            <span>Car Rentals</span>
          </div>
          <div className="headerListItem">
            <FontAwesomeIcon icon={faWandSparkles} />
            <span>Attractions</span>
          </div>
          <div className="headerListItem">
            <FontAwesomeIcon icon={faTaxi} />
            <span>Airport taxis</span>
          </div>
        </div>
        {type !== "list" && (
          <div>
            <h1 className="headerTitle">
              A lifetime of discount? It's Genius.
            </h1>
            <p className="headerDescription">
              Get rewarded for your travels - Unlock instant savings of 10% or
              more with a free WalidBooking account
            </p>
            <button className="btn">Sign in / Register</button>
            <div className="headerSearch">
              <div className="headerSearchItem">
                <FontAwesomeIcon icon={faBed} />
                <input
                  onChange={(e) => {
                    setDestination(e.target.value)
                  }}
                  type="text"
                  placeholder="where are you going?"
                  className="headerSearchInput"
                />
              </div>
              <div className="headerSearchItem">
                <FontAwesomeIcon icon={faCalendarDays} />
                <span
                  onClick={(e) => {
                    e.stopPropagation()
                    setShowDateRange(!showDateRange)
                  }}
                  className="headerSearchDate"
                >
                  {`${format(date[0].startDate, "dd/MM/yyyy")} to ${format(
                    date[0].endDate,
                    "dd/MM/yyyy"
                  )}`}
                </span>
                {showDateRange && (
                  <DateRange
                    className="datePicker"
                    editableDateInputs={true}
                    onChange={(item) => setDate([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={date}
                  />
                )}
              </div>
              <div className="headerSearchItem">
                <FontAwesomeIcon icon={faPerson} />
                <span
                  onClick={() => {
                    setShowOptions(!showOptions)
                  }}
                  className="headerSearchDate"
                >{`${options.adult} adult . ${options.children} children . ${options.room} room`}</span>
                {showOptions && (
                  <div className="options">
                    <div className="optionItem">
                      <span className="optionText">Adult</span>
                      <div className="optionCounter">
                        <button
                          disabled={options.adult <= 1}
                          className="optionCounterButton"
                          onClick={() => handleOption("adult", "d")}
                        >
                          -
                        </button>
                        <span className="optionCounterNumber">
                          {options.adult}
                        </span>
                        <button
                          className="optionCounterButton"
                          onClick={() => handleOption("adult", "i")}
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <div className="optionItem">
                      <span className="optionText">Children</span>
                      <div className="optionCounter">
                        <button
                          disabled={options.children <= 0}
                          className="optionCounterButton"
                          onClick={() => handleOption("children", "d")}
                        >
                          -
                        </button>
                        <span className="optionCounterNumber">
                          {options.children}
                        </span>
                        <button
                          className="optionCounterButton"
                          onClick={() => handleOption("children", "i")}
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <div className="optionItem">
                      <span className="optionText">Rooms</span>
                      <div className="optionCounter">
                        <button
                          disabled={options.room <= 1}
                          className="optionCounterButton"
                          onClick={() => handleOption("room", "d")}
                        >
                          -
                        </button>
                        <span className="optionCounterNumber">
                          {options.room}
                        </span>
                        <button
                          className="optionCounterButton"
                          onClick={() => handleOption("room", "i")}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="headerSearchItem">
                <button className="btn btnSearch" onClick={handleSearch}>
                  Search
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Header
